@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.scroll-down {
  position: relative;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  animation: fadeIn 2s ease-out 0.5s forwards;
  /* FIX TRANSFORM, OPACITY LEVEL, AND ANIMATION */
}

.scroll-down p {
  font-size: 18px;
  margin: 0;
  padding: 0;
  color: #ffffff;
}

.scroll-down .arrow {
  width: 20px;
  height: 20px;
  border: solid #ffffff;
  border-width: 0 5px 5px 0;
  transform: rotate(45deg);
  margin-top: 8px;
}
