@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Righteous&display=swap');

/* COLOR PALETTE */

:root {
  --navactive: rgb(7, 40, 204);
  --navhover: rgb(145, 139, 70);
  --skills: rgb(57, 96, 35);
  --activehover: rgb(81, 96, 214);
}

/* MASTER SETTINGS */

html, body, #root, .app {
  height: 100%;
}

html {
  background-color: #f3e2c7;
  color: black;
  scroll-behavior: smooth;
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  margin: 0px;
  height: auto;
}