.footer {
  display: grid;
  row-gap: 2vh;
  padding-bottom: 3vh;
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding-bottom: 2vh;
  width: 100%;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: var(--navhover);
  text-decoration: underline;
}

@media screen and (max-width: 500px) {
  .footer h5 {
    font-size: 1.75vh;
  }
  .footer {
    padding-bottom: 6vh;
  }
}