.contact-container {
display: flex;
justify-content: space-evenly;
align-items: center;
margin: 14vh 0vh;
}

.contact-text {
  max-width: 75vh;
  display: flex;
  flex-direction: column;
  row-gap: 2em;
}

.contact-photo {
  width: 60vh;
  height: 40vh;
}

@media screen and (max-width: 1500px) {
  .contact-container {
    margin: 8vh 0vh;
  }
  .contact-text {
    max-width: 50vh;
  }
}

@media screen and (max-width: 1100px) {
  .contact-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 1100px) {
  .contact-container {
    flex-direction: column;
    row-gap: 4vh;
    margin: 5vh 0vh;
  }
  .contact-photo {
    max-width: 40vh;
    max-height: 25vh;
  }
}

@media screen and (max-width: 500px) {
  #contact-section.page {
    margin-top: 12vh;
  }
}