/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
}

/* Modal Box */
.modal-box {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 4vh 4vh 5.5vh 4vh;
  border: 5px solid black;
  border-radius: 20px;
  width: 75%; /* Could be more or less, depending on screen size */
}

.modal-container {
  display: flex;
  flex-direction: column;
}

.modal-content {
  margin-top: 3vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5vh;
}

.modal-text-container {
  margin: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-description {
  display: flex;
  flex-direction: column;
  row-gap: 1vh;
}

.modal-photo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-photo {
  width: 100%;
  height: auto;
  border: 5px solid black;
}

.modal-photo:hover {
  border-color: gold;
}

.modal-links {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
}

.link-container {
  display: flex;
  column-gap: 1vh;
  cursor: pointer;
}

.link-container:hover {
  color: blue;
  text-decoration: underline;
}

.github-icon {
  height: 3vh;
}

/* Close Button */
.close {
  color: black;

  font-size: 28px;
  font-weight: bold;
  display: flex;
  align-self: flex-end;
}

.close:hover,
.close:focus {
  color: #aaa;
  text-decoration: none;
  cursor: pointer;
}


.modal.active {
  display: block;
  animation: showModal 0.2s forwards ease-in-out;
}

/* Software Engineer Modal */

/* CSS TERMINAL CREDIT: https://codepen.io/addyosmani/pen/avxmvN */

.fakeTerminal {
  display: flex;
  flex-direction: column;
  margin: 15% auto;
}

.fakeButtons {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid #000;
  position: relative;
  top: 6px;
  left: 6px;
  background-color: #ff3b47;
  border-color: #9d252b;
  display: inline-block;
}


.fakeMinimize {
  left: 11px;
  background-color: #ffc100;
  border-color: #9d802c;
}

.fakeZoom {
  left: 16px;
  background-color: #00d742;
  border-color: #049931;
}

.fakeButtons:hover {
  background-color: #ff6771;
  border-color: #c73940;
}

.fakeButtons.fakeMinimize:hover {
  background-color: #fecf45;
  border-color: #c99700;
}

.fakeButtons.fakeZoom:hover {
  background-color: #00ec47;
  border-color: #00c83c;
}

.fakeMenu {
  display: flex;
  justify-content: flex-start;
  width: 40em;
  box-sizing: border-box;
  height: 25px;
  background-color: #bbb;
  margin: 0 auto;
  padding: 1px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.fakeScreen {
  background-color: #151515;
  box-sizing: border-box;
  width: 40em;
  height: 25em;
  margin: 0 auto;
  padding: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.fakeTerminal p {
  position: relative;
  text-align: left;
  font-size: 1.25em;
  font-family: monospace;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  width: 0;
}

span {
  color: #fff;
  font-weight: bold;
}

.line1 {
  color: #9CD9F0;
  -webkit-animation: type .5s .5s steps(20, end) forwards;
  animation: type .5s .5s steps(20, end) forwards;
  margin-bottom: 1em;
}

.cursor1 {
  -webkit-animation: blink 1s 1.25s 2 forwards;
  animation: blink 1s 1.25s 2 forwards;
}

/* PARAGRAPH 2 */

.line2 {
  color: #CDEE69;
  -webkit-animation: type .25s 3.25s steps(20, end) forwards;
  animation: type .25s 3.25s steps(20, end) forwards;
}

.line3 {
  color: #CDEE69;
  -webkit-animation: type .25s 3.30s steps(20, end) forwards;
  animation: type .25s 3.30s steps(20, end) forwards;
}

.line4 {
  color: #CDEE69;
  -webkit-animation: type .25s 3.35s steps(20, end) forwards;
  animation: type .25s 3.35s steps(20, end) forwards;
}

.line5 {
  color: #CDEE69;
  -webkit-animation: type .25s 3.40s steps(20, end) forwards;
  animation: type .25s 3.40s steps(20, end) forwards;
}

.line6 {
  color: #CDEE69;
  -webkit-animation: type .25s 3.45s steps(20, end) forwards;
  animation: type .25s 3.45s steps(20, end) forwards;
}

.line7 {
  color: #CDEE69;
  -webkit-animation: type .25s 3.5s steps(20, end) forwards;
  animation: type .25s 3.5s steps(20, end) forwards;
}

.line8 {
  color: #CDEE69;
  -webkit-animation: type .25s 3.55s steps(20, end) forwards;
  animation: type .25s 3.55s steps(20, end) forwards;
}

.line9 {
  color: #CDEE69;
  -webkit-animation: type .25s 3.6s steps(20, end) forwards;
  animation: type .25s 3.6s steps(20, end) forwards;
  margin-bottom: 1em;
}

.cursor2 {
  -webkit-animation: blink 1s 4.3s 2 forwards;
  animation: blink 1s 4.3s 2 forwards;
}

/* PARAGRAPH 3 */

.line10 {
  color: #E09690;
  -webkit-animation: type .25s 6.65s steps(20, end) forwards;
  animation: type .25s 6.65s steps(20, end) forwards;
}

.line11 {
  color: #E09690;
  -webkit-animation: type .25s 6.7s steps(20, end) forwards;
  animation: type .25s 6.7s steps(20, end) forwards;
  margin-bottom: 1em;
}

.cursor3 {
  -webkit-animation: blink 1s 7.5s 1 forwards;
  animation: blink 1s 7.5s 1 forwards;
}

/* PARAGRAPH 4 */

.line12 {
  color: #eb9a4e;
  -webkit-animation: type .25s 8.85s steps(20, end) forwards;
  animation: type .25s 8.85s steps(20, end) forwards;
  margin-bottom: 1em;
}

.cursor4 {
  -webkit-animation: blink 1s 9.65s 1 forwards;
  animation: blink 1s 9.65s 1 forwards;
}

/* PARAGRAPH 5 (TERMINAL PROMPT) */

.line13 {
  color: #fff;
  -webkit-animation: type .5s 11s steps(20, end) forwards;
  animation: type .5s 11s steps(20, end) forwards;
}

.cursor5 {
  -webkit-animation: blink 1s 11.8s infinite;
  animation: blink 1s 11.8s infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes type {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes type {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Fade in Effect on Modal */
@keyframes showModal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes closeModal {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@media screen and (max-width: 1500px) {
  .modal-box {
    margin: 3% auto;
  }
  .fakeTerminal {
    margin: 10% auto;
  }
}

@media screen and (max-width: 1200px) {
  .modal-box {
    margin: 10% auto;
    width: 75%;
    padding: 2vh;
  }
  .modal-photo {
    width: 40vh;
    height: 30vh;
  }
  .modal-content {
    display: flex;
    flex-direction: column;
  }
  .modal-container h2 {
    font-size: 30px;
  }
  .modal-container h4 {
    font-size: 20px;
  }
  .modal-container h6 {
    font-size: 12px;
  }
  .modal-text-container {
    margin: 1vh;
  }
  .fakeTerminal {
    margin: 15% auto;
  }
  .fakeTerminal p {
    font-size: 10px;
    white-space: normal;
  }
  .fakeMenu {
    width: 50vh;
  }
  .fakeScreen {
    width: 50vh;
    height: auto;
  }
}

@media screen and (max-width: 500px) {
  .modal-container h2 {
    font-size: 25px;
  }
  .modal-photo {
    width: 30vh;
    height: 20vh;
  }
}