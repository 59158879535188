.about {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 4vh;
}

.about-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.bio-container {
  margin-top: 5em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.bio {
  max-width: 75vh;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}

.silly-link {
  color: blue;
  cursor: pointer;
}

.silly-link:hover {
  color: red;
}

a.silly-link:hover {
  text-decoration: none;
}

.eddie {
  width: 100%;
  max-width: 60vh;
  height: auto;
}

.video-container {
  width: fit-content;
}

.video {
  width: 50vh;
  height: 50vh;
}


@media screen and (max-width: 1200px) {
  .about {
    flex-direction: column;
  }
  .bio-container {
    flex-direction: column;
    row-gap: 4vh;
  }
}

@media screen and (max-width: 500px) {
  .isa {
    margin-top: 1vh;
  }
  .about {
    row-gap: 5vh;
  }
  .video {
    width: 40vh;
    height: 40vh;
  }
}