#projects-section {
  margin-bottom: 15vh;
  /* DELETE AFTER ADDING MORE PROJECTS! */
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2vh;
  margin: 5vh;
}

.projects-text-container {
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2vh;
}

.projects-text {
  max-width: 150vh;
}

.project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1vh;
  border: 5px solid black;
  padding: 2vh;
  cursor: pointer;
}

.project-image {
  width: 35vh;
  height: 25vh;
  border: 5px solid black;
}

#noted:hover {
  background-color: #0033ff;
  color: yellow;
}

#lerpr:hover {
  background-color: #313646;
  color: white;
}

#movie-royale:hover {
  background-color: #a1ada3;
  color: #4e3f3f;
}

#simon:hover {
  background-color: black;
  color: white;
}

@media screen and (max-width: 1200px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 4vh;
    margin: 0vh 20vh;
  }
  .projects-text-container {
    margin: 2vh;
  }
}

@media screen and (max-width: 800px) {
  .projects-container {
    margin: 0vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .projects-text-container {
    margin-top: 4vh;
  }
  .project-image {
    width: 30vh;
    height: 20vh;
  }
}
