@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.homepage {
  background-image: url('../../Assets/flare.jpg');
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.home-container {
  height: 80vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  padding: 9vh;
  animation: fadeIn 2s ease-out 0.5s forwards;
}

@media screen and (max-width: 500px) {
  .homepage {
    background-position: 60%;
  }
  .home-container {
    height: 70vh;
    padding: 7.5vh 2vh;
    justify-content: center;
    align-items: center;
  }
h1 {
    font-size: 8vh;
    line-height: 90%;
  }
  .home-container h4 {
    font-size: 3vh;
  }
}