.header {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background-color: #f3e2c7;
}

.navbar {
  position: sticky;
  top: -1px;
  padding: 2vh 0vh;
  background-color: #f3e2c7;
  width: 100%;
}

.nav-link {
  display: flex;
  justify-content: center;
}

/* h5 represents nav-link text! */

.nav-link h5:hover {
  color: var(--navhover);
  text-decoration: underline;
  cursor: pointer;
}

.nav-active {
  color: blue;
}

.nav-active h5:hover {
  color: var(--activehover);
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .header h5 {
    font-size: 2vh;
  }
}