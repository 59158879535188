.app {
  text-align: center;
  height: 100%;
}

/* PAGE LAYOUT */

.page {
  padding-top: 10vh;
  margin-bottom: 2vh;
}

.page-header {
  margin-bottom: 2em;
  text-decoration: underline;
}

* {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

/* FONT SIZES */

h1 {
  font-size: 60px;
  font-family: 'Hanken Grotesk', sans-serif;
}

h2 {
  font-size: 48px;
  font-family: 'Hanken Grotesk', sans-serif;
}

h3 {
  font-size: 36px;
  font-family: 'Hanken Grotesk', sans-serif;
}

h4 {
  font-size: 28px;
  font-family: 'Hanken Grotesk', sans-serif;
}

h5 {
  font-size: 24px;
  font-family: 'Hanken Grotesk', sans-serif;
}

h6 {
  font-size: 2vh;
  font-family: 'Hanken Grotesk', sans-serif;
}

p {
  font-size: 12px;
  font-family: 'Hanken Grotesk', sans-serif;
}

@media screen and (max-width: 800px) {
  .page {
    margin: 0vh 2vh 4vh 2vh;
  }
  h1 {
    font-size: 8vh;
  }
}