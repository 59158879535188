.resume {
  margin-top: 2em;
  height: 71vh;
  width: 55vh;
  border: 0px;
}

@media screen and (max-width: 800px) {
  .resume {
    margin-top: 5em;
    height: 60vh;
    width: 47vh;
  }
}

@media screen and (max-width: 500px) {
  .resume {
    margin-top: 15vh;
    height: 48vh;
    width: 38vh;
  }
}

